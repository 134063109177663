import { SliderColorSchemes } from "../components/shared/Slider";
import { SectionBackgroundColorType } from "../types/pageBuilder";

export const chooseSliderColorScheme = (color: SectionBackgroundColorType) => {
  switch (color) {
    case "light_gray":
      return SliderColorSchemes.gray;
    case "light_green":
      return SliderColorSchemes.green;
    case "white":
      return SliderColorSchemes.white;
  }
};
