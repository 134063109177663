
import { CSS } from "@stitches/react/types/css-util";
import { StyledComponent } from "@stitches/react/types/styled-component"
import { ReactNode } from "react";

export enum PageBuilderBlocks {
  hero = 'ComponentHeroHeroSection',
  supportSection = 'ComponentSectionsSupportSection',
  cardListSection = 'ComponentCardListSectionsCardListSection',
  coloredCardsSection = 'ComponentCardListSectionsColoredCardsSection',
  coloredTabsSection = 'ComponentTabsSectionsTabsSwitcherBlock',
  categorizedListSection = 'ComponentListSectionsCategorizedListSection',
  richtextSection = 'ComponentSectionsRichtextContentSection',
  gallerySection = 'ComponentSectionsImageGallerySection'
};

export type ButtonDirectionsType = 'contact_us' | 'employment' | 'support';
export type SectionBackgroundColorType = 'light_green' | 'light_gray'| 'white';
export type HeroBackgroundType = 'page_color'| 'transparent';
export type ImagePositionType = "left" | "right";
export type TextColorType = "dark" | "light";
export type SectionSpacingType = 'thin' | 'regular'| 'wide'
export type SupportBlockVariant = "minimized" | "standard";
export type SectionVisibility = 'visible'| 'hidden';


export type ImageType = {
  data: {
    attributes: {
      url: string;
      caption: string;
    };
  };
};

export type CustomStylesType = {
  P?: StyledComponent<"p">;
  Container?: StyledComponent<'div'>;
  BuilderWrapper?: StyledComponent<'div'>;
  Li?: StyledComponent<'li'>;
};


export type ButtonType = {
  Button_text: string;
  Button_link_direction: ButtonDirectionsType
};

export type ListType = {
  text: string;
};

//hoc section wrapper

export type SectionHocProps = {
  title?: string;
  content?:string;
  spacing: SectionSpacingType;
  backgroundColor:SectionBackgroundColorType;
  children?: ReactNode;
  css?:CSS
};

//hero

export type HeroBlockType = {
  __typename: PageBuilderBlocks,
  Hero_section_visibility: SectionVisibility;
  Hero_image_position: ImagePositionType;
  Hero_image: ImageType;
  Page_headline: string;
  Page_hero_description: string;
  Hero_mobile_text_color: TextColorType;
  Hero_button?: ButtonType[];
  Hero_background: HeroBackgroundType
};

export type HeroComponentProps = {
  imagePosition: ImagePositionType;
  heroImage: ImageType;
  backgroundColor: HeroBackgroundType
  headline: string;
  description: string;
  mobileTextColor: TextColorType;
  button?: ButtonType[];
  pageColor?: string;
};

//support

export type SupportBlockType = {
  __typename: PageBuilderBlocks;
  Support_section_visibility: SectionVisibility;
  Support_block_type: SupportBlockVariant;
  Support_section_spacing: SectionSpacingType
  Support_section_background_color: SectionBackgroundColorType
};

export type SupportComponentProps = {
  pageColor?: string;
  blockType: SupportBlockVariant;
};

//production section

export type CategorizedCardsBlockType = {
  __typename: string;
  Categorized_list_section_visibility:SectionVisibility;
  Categorized_list_section_title?:string;
  Categorized_list_section_content?:string;
  Categorized_list_section_spacing: SectionSpacingType;
  Categorized_list_section_background_color:SectionBackgroundColorType;
  Category_list: {
    Category_title: string;
    List_element: {
      Card_title: string;
      Card_icon: ImageType;
    }[];
  }[];
};

export type CategorizedListComponentProps = {
    blocks: {
      Category_title: string;
      List_element: {
          Card_title: string;
          Card_icon: ImageType;
        }[]
    }[];
  };

//cards block

export type CardListBlockType = {
  __typename: PageBuilderBlocks;
  Card_list_section_visibility:SectionVisibility;
  Card_list_section_title: string;
  Card_list_section_content: string;
  Card_list_section_background_color: SectionBackgroundColorType;
  Card_list_section_spacing: SectionSpacingType
  Card: {
    Card_title?: string;
    Card_icon: ImageType;
    Card_text: string;
    Card_color: SectionBackgroundColorType;
  }[]
}

export type CardListBlockProps = {
  pageColor?: string;
  backgroundColor: SectionBackgroundColorType;
  blockData: CardListBlockType
}

//color cards block

export type ColoredCardListBlockType = {
  __typename: PageBuilderBlocks;
  Colored_cards_section_title: string;
  Colored_cards_section_content: string;
  Colored_cards_section_visibility:SectionVisibility;
  Colored_cards_section_background_color: SectionBackgroundColorType;
  Colored_cards_section_spacing:SectionSpacingType;
  Colored_card: ColoredCard[];
}

export type ColoredCard = {
  Card_title: string;
  Card_description: string;
  Card_icon?: ImageType;
  Card_hex_color?: string;
  Content_color: TextColorType;
  List_element?: ListType[];
}

export type RichtextBlockType = {
  __typename:string
  Richtext_content_section_visibility: SectionVisibility;
  Richtext_content_section_title?: string;
  Richtext_content_section_spacing: SectionSpacingType;
  Richtext_content_section_background_color:SectionBackgroundColorType;
  Richtext_content_section_content?:string;
};

export type ImageGalleryBlockType = {
  __typename:string;
  Gallery_section_visibility:SectionVisibility;
  Gallery_section_title?:string;
  Gallery_section_content?: string;
  Gallery_section_spacing:SectionSpacingType
  Gallery_section_background_color:SectionBackgroundColorType
  Gallery_images:{data:{attributes:{url:string}}[]}
}


export type ColoredTab = {
  Tab_title: string;
  Tab_description: string;
  Tab_image: ImageType;
  List_element?: ListType[];
};

export type ColoredTabsBlockType = {
  __typename:string;
  Colored_tabs_section_visibility:SectionVisibility;
  Colored_tabs_section_title?:string;
  Colored_tabs_section_spacing:SectionSpacingType;
  Colored_tabs_section_background_color:SectionBackgroundColorType;
  Tab: ColoredTab[]
};

export type ColoredCardsBlockProps = {
  pageColor?: string;
  blockData: ColoredCardListBlockType;
};

export type DynamicComponents = HeroComponentProps | SupportComponentProps | CardListBlockProps | ColoredCardsBlockProps | CategorizedListComponentProps ;

export type DynamicBlocks = HeroBlockType | SupportBlockType | CardListBlockType | ColoredCardListBlockType | CategorizedCardsBlockType |RichtextBlockType |ColoredTabsBlockType |ImageGalleryBlockType;


export type DynamicPageData = {
  SEO_page_title: string;
  SEO_page_description: string;
  SEO_preview_image?: {
    data: { attributes: { url: string } };
  };
  Page_slug: string;
  Page_color_theme_hex?: string;
  Page_sections: DynamicBlocks[];
};