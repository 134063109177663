import * as React from "react";
import { GetServerDataProps, graphql, PageProps } from "gatsby";
import { sendRequestToAPI } from "../../api/graphqlRequest";
import { DynamicView } from "../../views/DynamicView.tsx/DynamicView";
import { DynamicBlocks } from "../../types/pageBuilder";
import { Layout } from "../../layout";
import { useI18next } from "gatsby-plugin-react-i18next";

export type DynamicPageData = {
  notFound: boolean;
  page: {
    SEO_page_title: string;
    SEO_page_description: string;
    SEO_preview_image?: {
      data: { attributes: { url: string } };
    };
    Page_slug: string;
    Page_color_theme_hex?: string;
    Page_sections: DynamicBlocks[];
  };
};

const DynamicPage: React.FC<PageProps> = ({ serverData }) => {
  const { page, notFound } = serverData as DynamicPageData;

  const { navigate } = useI18next();

  React.useEffect(() => {
    notFound && navigate("/404", { replace: true });
  }, [notFound]);

  return (
    <Layout>
      <DynamicView serverData={page} />
    </Layout>
  );
};

interface ContextParams {
  [key: string]: string;
}

export async function getServerData(context: GetServerDataProps) {
  const { id } = context.params as ContextParams;

  const getData = async () => {
    try {
      const { data } = await sendRequestToAPI(
        `
        query($url:String, $locale: I18NLocaleCode){
          pages(locale: $locale, filters:{Page_slug:{eq: $url}}){
            data{
              attributes{
                SEO_page_title
                SEO_page_description
                Page_color_theme_hex
                SEO_preview_image{
                  data{attributes{
                    url
                  }}
                }
                Page_sections{
                  ... on ComponentHeroHeroSection{
                    __typename
                    Hero_section_visibility
                    Page_headline
                    Page_hero_description
                    Hero_mobile_text_color
                    Hero_image{data{attributes{url}}}
                    Hero_button{
                    Button_text
                    Button_link_direction}
                    Hero_image_position
                    Hero_background
                  }
                  ... on ComponentSectionsSupportSection{
                    __typename
                    Support_block_type
                    Support_section_spacing
                    Support_section_background_color
                    Support_section_visibility
                  }
                  ... on ComponentCardListSectionsCardListSection{
                    __typename
                    Card_list_section_title
                    Card_list_section_content
                    Card_list_section_background_color
                    Card_list_section_spacing
                    Card_list_section_visibility
                    Card{
                      Card_icon{data{attributes{
                        url
                        caption}}}
                      Card_text
                      Card_color
                      Card_title
                    }
                  }
                  ... on ComponentCardListSectionsColoredCardsSection{
                    __typename
                    Colored_cards_section_visibility
                    Colored_cards_section_title
                    Colored_cards_section_content
                    Colored_cards_section_background_color
                    Colored_cards_section_spacing
                    Colored_card{
                      Card_title
                      Card_hex_color
                      Card_description
                      Content_color
                      Card_icon{data{attributes{url}}}
                      List_element{
                        text
                      }
                    }
                  }
                  ... on ComponentSectionsRichtextContentSection{
                    __typename
                    Richtext_content_section_visibility
                    Richtext_content_section_title
                    Richtext_content_section_spacing
                    Richtext_content_section_background_color
                    Richtext_content_section_content
                  }
                  ... on ComponentSectionsImageGallerySection{
                    __typename
                    Gallery_section_visibility
                    Gallery_section_title
                    Gallery_section_content
                    Gallery_section_spacing
                    Gallery_section_background_color
                    Gallery_images{
                      data{attributes{url}}
                    }
                  }
                  ... on ComponentTabsSectionsTabsSwitcherBlock{
                    __typename
                    Colored_tabs_section_visibility
                    Colored_tabs_section_title
                    Colored_tabs_section_spacing
                    Colored_tabs_section_background_color
                    Tab{
                      Tab_title
                      Tab_description
                      Tab_image{data{attributes{url}}}
                      List_element{
                      text
                      }
                    }
                  }
                  ... on ComponentListSectionsCategorizedListSection{
                    __typename
                    Categorized_list_section_visibility
                    Categorized_list_section_title
                    Categorized_list_section_content
                    Categorized_list_section_spacing
                    Categorized_list_section_background_color
                    Category_list{
                      Category_title
                      List_element(pagination: {limit:100}){
                        Card_title
                        Card_icon{data{attributes{url}}}
                      }
                    }
                  }
                }
              }
              
            }
          }}
      `,
        { url: "edi" },
        {},
        process.env.GATSBY_API_URL
      );
      return data;
    } catch (err) {
      return err;
    }
  };
  const { data } = await getData();
  const notFound = !data.pages?.data?.length;

  const pageData: DynamicPageData = {
    page: data.pages.data[0].attributes,
    notFound,
  };
  return {
    props: { ...pageData },
  };
}

export default DynamicPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
