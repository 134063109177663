import { Resolution } from "../types";

  export const getPageSize = (resolution: Resolution) => {
    if (resolution === "desktop") {
      return 7;
    } else if (resolution === "mobile") {
      return 5;
    } else {
      return undefined;
    }
  };
