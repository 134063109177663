import React, { useMemo, useState } from "react";
import { styled } from "../../../../stitches.config";
import { HeroComponentProps, TextColorType } from "../../../types/pageBuilder";
import { getTextColor } from "../../../utils";
import { Button } from "../shared/Button";
import { DynamicPagesRichTextParser } from "../shared/RichTextParser";

export const HeroBlock: React.FC<HeroComponentProps> = ({
  pageColor,
  heroImage,
  headline,
  description,
  mobileTextColor,
  imagePosition,
  backgroundColor,
  button,
}) => {
  const [backgroundVisible, setBackgroundVisible] = useState<"none" | "block">(
    "none"
  );

  const mobileTextColorVar = getTextColor(mobileTextColor);

  const heroStyles = useMemo(
    () => ({
      P: p(mobileTextColorVar),
      Container,
      BuilderWrapper: builderstyles(mobileTextColor, pageColor),
    }),
    [mobileTextColor, pageColor, mobileTextColorVar]
  );

  const heroBackground =
    backgroundColor === "transparent" ? "transparent" : pageColor;

  return (
    <HeroSection
      position={imagePosition}
      css={{ backgroundColor: heroBackground }}
    >
      <StyledTextSection>
        <TextWrapper>
          <Heading mobileColor={mobileTextColor}>{headline}</Heading>
          <DynamicPagesRichTextParser
            customColor={pageColor}
            customStyles={heroStyles}
            content={description}
          />
          {!!button?.length && (
            <ButtonWrap>
              {button.map((item, i) => (
                <Button
                  key={i}
                  buttonColor={pageColor}
                  direction={item?.Button_link_direction}
                  applyPageColor={backgroundColor === "transparent"}
                >
                  {item.Button_text}
                </Button>
              ))}
            </ButtonWrap>
          )}
        </TextWrapper>
      </StyledTextSection>
      <StyledPicSection>
        <ImageWrapper
          css={{
            "&::after": {
              backgroundColor: heroBackground,
              display: backgroundVisible,
            },
          }}
        >
          <Image
            src={heroImage.data.attributes.url}
            alt={heroImage.data.attributes.caption}
            ref={(ref) => {
              if (!ref) {
                return;
              }
              const img = ref;
              const updateFunc = () => {
                setBackgroundVisible("block");
              };
              img.onload = updateFunc;
              if (img.complete) {
                updateFunc();
              }
            }}
          />
        </ImageWrapper>
      </StyledPicSection>
    </HeroSection>
  );
};

const p = (color: string) => {
  const p = styled("p", {
    color: color,
    fontSize: "17px",
    fontWeight: 400,
    lineHeight: "24px",
    margin: "12px 0px",
    "@md": {
      color: "$text",
      maxWidth: "620px",
    },
  });

  return p;
};

const builderstyles = (color: TextColorType, pageColor?: string) => {
  const linkColor = color === "light" ? "$white" : pageColor || "$accent";

  const BuilderWrapper = styled("div", {
    margin: "0",
    a: {
      fontWeight: 600,
      color: linkColor,
      textDecoration: "underline",
      "@md": {
        color: pageColor,
        textDecoration: "none",
      },
    },
  });
  return BuilderWrapper;
};

const TextWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "640px",
  margin: "0",
  float: "right",
});

const Image = styled("img", {
  width: "100%",
  maxWidth: 500,
});

const Heading = styled("h1", {
  fontWeight: 700,
  fontSize: "32px",
  lineHeight: "40px",
  margin: 0,
  "@md": {
    fontSize: "48px",
    lineHeight: "56px",
  },
  variants: {
    mobileColor: {
      dark: {
        color: "$heading",
        "@md": {
          color: "$heading",
        },
      },
      light: {
        color: "$white",
        "@md": {
          color: "$heading",
        },
      },
    },
  },
});

const ImageWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  width: "100%",
  maxWidth: "256px",
  margin: "0",
  "@md": {
    maxWidth: "640px",
    alignItems: "flex-end",
    "& > img": { zIndex: 1 },
    "&::after": {
      content: "",
      position: "absolute",
      width: "70%",
      height: "85%",
      borderRadius: "32px",
      transform: "translate(0%, 20%)",
      right: 0,
      top: -20,
    },
  },
  variants: {
    backgroundColor: {
      transparent: {
        "@md": {
          "&::after": {
            backgroundColor: "$white",
          },
        },
      },
      green: {
        "@md": {
          "&::after": {
            backgroundColor: "$accent",
          },
        },
      },
      blue: {
        "@md": {
          "&::after": {
            backgroundColor: "$darkBlue",
          },
        },
      },
    },
  },
});

const HeroSection = styled("section", {
  display: "flex",
  flexDirection: "column-reverse",
  margin: "0 auto",
  width: "100%",
  "@md": {
    padding: "0px 0px 86px 0px",
    backgroundColor: "$white !important",

    overflow: "hidden",
  },
  variants: {
    position: {
      right: {
        "@md": {
          flexDirection: "row",
        },
      },
      left: {
        "@md": {
          flexDirection: "row-reverse",
        },
      },
    },
  },
});

const StyledTextSection = styled("div", {
  padding: "0px 16px 0 16px",
  margin: "0 auto",
  "@md": {
    padding: "86px 0 0",
    flexBasis: "50%",
    position: "relative",
  },
});

const StyledPicSection = styled("div", {
  padding: "55px 16px 35px",
  margin: "0 auto",
  "@md": {
    padding: "86px 0 0",
    flexBasis: "50%",
    display: "flex",
    alignItems: "center",
  },
  "@mx": {
    padding: "86px 0 0",
  },
});

const ButtonWrap = styled("div", {
  margin: "20px 0 32px 0",
  "@md": {
    margin: "20px 0 0 0",
  },
});

const Container = styled("div", {
  margin: 0,
  maxWidth: "unset",
});
