import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { ReactNode, useState } from "react";
import { styled } from "../../../stitches.config";

export type Tab = {
  title: string;
  content: ReactNode;
};

type Props = {
  tabs: Tab[];
  tabsPosition: "center" | "left";
  tabsBackground: "gray" | "transparent";
};

export const Tabs = ({ tabs, tabsBackground, tabsPosition }: Props) => {
  const { t } = useTranslation();
  const [currentTabIdx, setCurrentTabIdx] = useState(0);

  return (
    <>
      <ButtonsWrap position={tabsPosition}>
        {tabs.map((tab, idx) => (
          <TabButton
            background={tabsBackground}
            isActive={idx === currentTabIdx}
            isActiveGray={
              !!(idx === currentTabIdx && tabsBackground === "gray")
            }
            onClick={() => setCurrentTabIdx(idx)}
            key={idx}
          >
            {t(tab.title)}
          </TabButton>
        ))}
      </ButtonsWrap>
      <ContentWrap>
        {tabs.map((item, idx) => (
          <TabContent key={idx} visible={idx === currentTabIdx}>
            {item.content}
          </TabContent>
        ))}
      </ContentWrap>
    </>
  );
};

const ButtonsWrap = styled("div", {
  display: "flex",
  variants: {
    position: {
      center: {
        justifyContent: "center",
      },
      left: {
        justifyContent: "flex-start",
      },
    },
  },
});

const TabButton = styled("button", {
  fontWeight: 700,
  fontSize: "16px",
  border: "none",
  lineHeight: "24px",
  backgroundColor: "transparent",
  padding: "8px",
  cursor: "pointer",
  color: "$white",
  transition: "all 0.5s ease",
  borderRadius: "4px",
  "&:not(:last-child)": {
    marginRight: "12px",
  },
  "&:hover": {
    background: "$tabHover",
  },
  variants: {
    isActive: {
      true: {
        backgroundColor: "$tabHover !important",
        color: "$white !important",
      },
    },
    isActiveGray: {
      true: { backgroundColor: "$heading !important" },
    },
    background: {
      gray: {
        color: "#7A7A7A",
        backgroundColor: "$secondaryBackground",
      },
      transparent: {
        backgroundColor: "transparent",
      },
    },
  },
});

const ContentWrap = styled("div", {
  marginTop: "24px",
  width: "100%",
  position: "relative",
});

const TabContent = styled("div", {
  opacity: 0,
  position: "absolute",
  top: 0,
  width: "100%",
  transition: "opacity 500ms ease-in-out",
  variants: {
    visible: {
      true: {
        position: "static",
        display: "initial",
        opacity: 1,
      },
    },
  },
});
