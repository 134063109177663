import React, { useEffect } from "react";
import { styled } from "../../../stitches.config";
import { useNoScroll } from "../../hooks/useNoScroll";
import Prev from "../../../static/icons/common/prev.svg";
import Next from "../../../static/icons/common/next.svg";
import { useEscapeKey } from "../../hooks/useEscapeKey";
import { isBrowser } from "./LiveSupport";
import { useArrowKeys } from "../../hooks/useArrowsKeys";

type Props = {
  image: string;
  totalElements: number;
  current: number;
  next: () => void;
  prev: () => void;
  close: () => void;
};

export const FullScreenImageGallery: React.FC<Props> = ({
  image,
  current,
  totalElements,
  next,
  prev,
  close,
}) => {
  const isNextLocked = current === totalElements - 1;
  const isPrevLocked = !current;

  if (isBrowser()) {
    useEscapeKey(close);
    useNoScroll();
    useArrowKeys({
      prev: () => !isPrevLocked && prev(),
      next: () => !isNextLocked && next(),
    });
  }

  return (
    <Overlay>
      <CloseBtn onClick={close}>&#10005;</CloseBtn>
      <OverlayContent>
        <ImageContainer>
          <Image src={image} />
        </ImageContainer>
        <Navigation>
          <StyledArrowContainer locked={isPrevLocked} onClick={() => prev()}>
            <Prev />
          </StyledArrowContainer>
          <PaginationCount>{`${current + 1}/${totalElements}`}</PaginationCount>
          <StyledArrowContainer locked={isNextLocked} onClick={() => next()}>
            <Next />
          </StyledArrowContainer>
        </Navigation>
      </OverlayContent>
    </Overlay>
  );
};

const Overlay = styled("div", {
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  position: "fixed",
  top: 0,
  left: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100vw",
  height: "100vh",
  zIndex: 10,
  backdropFilter: "blur(10px)",
});

const OverlayContent = styled("div", {
  maxWidth: 1120,
  flexBasis: "1280px",
  display: "flex",
  padding: "64px 12px",
  justifyContent: "center",
  gap: 48,
  alignItems: "center",
  flexDirection: "column",
  height: "100%",
});

const ImageContainer = styled("div", {
  display: "flex",
  maxHeight: "90%",
  maxWidth: "100%",
  flexGrow: 1,
  justifyContent: "center",
  alignItems: "center",
});

const Image = styled("img", {
  objectFit: "contain",
  maxWidth: "100%",
  maxHeight: "100%",
  width: "100%",
  height: "100%",
});

const StyledArrowContainer = styled("div", {
  backgroundColor: "transparent",
  display: "flex",
  flexBasis: "24px ",
  alignItems: "center",
  justifyContent: "center",
  height: "24px",
  border: "1px solid $footer",

  borderRadius: "50%",
  cursor: "pointer",
  transition: "all 0.3s ease",
  "& > svg": {
    fill: "$footer",
    display: "inline-block",
  },
  "&:hover": {
    border: "1px solid $accent",
    "& > svg": {
      fill: "$accent",
    },
  },
  variants: {
    locked: {
      true: {
        border: "1px solid $text",
        pointerEvents: "none",
        "& > svg": {
          fill: "$text",
        },
        "&:hover": {
          border: "1px solid $footer",
          "& > svg": {
            fill: "$footer",
          },
        },
      },
    },
  },
});

const PaginationCount = styled("span", {
  color: "$footer",
  fontWeight: 700,
  fontSize: "17px",
  lineHeight: "24px",
});
const Navigation = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  margin: "0px auto 0px auto",
  alignItems: "center",
  width: "153px",
  userSelect: "none",
  backgroundColor: "transparent",
});

const CloseBtn = styled("span", {
  position: "absolute",
  fontSize: "32px",
  color: "$footer",
  fontWeight: 700,
  top: 10,
  cursor: "pointer",
  right: 20,
  "&:hover": {
    color: "$accent",
  },
});
