import React from "react";
import { styled } from "../../../../stitches.config";

type Props = {
  icon: string;
  desc: string;
  title?: string;
  cardColor?: string;
  pageColor?: string;
};

export const Card: React.FC<Props> = ({
  icon,
  desc,
  title,
  cardColor = "$white",
  pageColor = "$accent",
}) => {
  return (
    <CardBox css={{ background: cardColor }}>
      {!!title && <CardTitle>{title}</CardTitle>}
      <ImgWrap css={{ backgroundColor: pageColor }}>
        <img src={icon} />
      </ImgWrap>
      <CardDesc>{desc}</CardDesc>
    </CardBox>
  );
};

const CardBox = styled("div", {
  padding: "24px",
  backgroundColor: "$white",
  borderRadius: "8px",
  textAlign: "center",
  maxWidth: "382px",
  "@md": {
    maxWidth: "unset",
    height: "100%",
    filter:
      "drop-shadow(0px 122px 49px rgba(0, 0, 0, 0.01)) drop-shadow(0px 69px 41px rgba(0, 0, 0, 0.03)) drop-shadow(0px 31px 31px rgba(0, 0, 0, 0.04)) drop-shadow(0px 8px 17px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05))",
  },
});

const ImgWrap = styled("span", {
  display: "block",
  width: "52px",
  height: "52px",
  margin: "0 auto",
  borderRadius: "50%",
  padding: "16px 12px",
});

const CardTitle = styled("h3", {
  fontSize: "15px",
  lineHeight: "20px",
  fontWeight: 700,
  margin: "0px auto 24px auto",
  color: "$heading",
  "@md": {
    fontSize: "16px",
    lineHeight: "24px",
    margin: "0px auto 16px auto",
  },
});

const CardDesc = styled("p", {
  marginTop: "24px",
  marginBottom: "0px !important",
  fontSize: "15px",
  lineHeight: "20px",
  fontWeight: 400,
  minHeight: "150px",
  maxHeight: "270px",
  color: "$text",
  "@md": {
    fontSize: "14px",
    marginTop: "16px",
    minHeight: "unset",
    maxHeight: "unset",
  },
});
