import React from "react";
import { BlockDefiner } from "../../components/PageBuilder/BlockDefiner";
import { Seo } from "../../components/shared/Seo";
import { DynamicPageData } from "../../types/pageBuilder";

type Props = {
  serverData: DynamicPageData;
};

export const DynamicView: React.FC<Props> = ({ serverData }) => {
  const {
    SEO_page_description,
    SEO_page_title,
    SEO_preview_image,
    Page_sections,
    Page_color_theme_hex,
    Page_slug,
  } = serverData;
  const color = Page_color_theme_hex || undefined;
  return (
    <>
      <Seo
        title={SEO_page_title}
        description={SEO_page_description}
        image={SEO_preview_image?.data.attributes.url}
      />
      {Page_sections.map((item, i) => {
        return (
          <BlockDefiner
            pageThemeColor={color}
            block={item}
            pageSlug={Page_slug}
            key={i}
          />
        );
      })}
    </>
  );
};
