import { Link } from "gatsby-plugin-react-i18next";
import React, { ReactNode } from "react";
import { styled } from "../../../../stitches.config";
import { routes } from "../../../routes/routes";
import { ButtonDirectionsType } from "../../../types/pageBuilder";

const ButtonLinks = {
  contact_us: routes.contact.contactForm,
  employment: routes.contact.employment,
  support: routes.support.supportForm,
};

type Props = {
  direction?: ButtonDirectionsType;
  children: ReactNode;
  buttonColor?: string;
  hasOpacity?: boolean;
  externalLink?: string;
  applyPageColor?: boolean;
};

export const Button: React.FC<Props> = ({
  direction = "contact_us",
  children,
  buttonColor,
  hasOpacity,
  externalLink,
  applyPageColor,
}) => {
  const cssBackgroundColorConfig = applyPageColor
    ? { backgroundColor: `${buttonColor} !important` }
    : { "@md": { backgroundColor: buttonColor } };

  const StyledButton = getButton(!!externalLink);
  const hrefTo = externalLink
    ? { href: externalLink }
    : { to: ButtonLinks[direction] };

  return (
    <StyledButton
      isColorDefined={!!buttonColor}
      hasOpacity={!!hasOpacity}
      applyPageColor={!!applyPageColor}
      css={cssBackgroundColorConfig}
      {...hrefTo}
    >
      {children}
    </StyledButton>
  );
};

const getButton = (external: boolean) => {
  const StyledButton = styled(external ? "a" : Link, {
    display: "inline-block",
    textAlign: "center",
    cursor: "pointer",
    textDecoration: "none",
    borderRadius: "4px",
    fontWeight: 700,
    lineHeight: "24px",
    fontSize: "17px",
    border: "none",
    padding: "12px 24px",
    transition: "all 0.5s ease",
    width: "100%",
    maxWidth: "640px",
    margin: "0 auto",
    "@md": {
      width: "fit-content",
    },
    "&:hover": {
      opacity: 0.75,
    },
    variants: {
      isColorDefined: {
        true: {
          backgroundColor: "$white",
          color: "$text",
          "@md": {
            color: "$white",
          },
        },
        false: {
          backgroundColor: "$accent",
          color: "$white",
        },
      },

      hasOpacity: {
        true: {
          "@md": {
            backgroundColor: "rgba(0, 0, 0, 0.20) !important",
            color: "$white",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.40) !important",
            },
          },
        },
      },
      applyPageColor: {
        true: {
          color: "$white",
        },
      },
    },
  });

  return StyledButton;
};
