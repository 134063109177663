import React from "react";
import { SwiperSlide } from "swiper/react";
import { styled } from "../../../../stitches.config";
import { Slider } from "../../shared/Slider";
import { CardListBlockProps } from "../../../types/pageBuilder";
import { Card } from "./Card";
import { chooseSliderColorScheme } from "../../../utils";

export const CardsBlock: React.FC<CardListBlockProps> = ({
  pageColor,
  backgroundColor,
  blockData,
}) => {
  return (
    <>
      <SliderWrap>
        <Slider
          colorScheme={chooseSliderColorScheme(backgroundColor)}
          total={blockData.Card.length}
        >
          {blockData.Card.map((item, idx) => (
            <StyledSlide key={idx}>
              <Card
                desc={item.Card_text}
                icon={item.Card_icon.data.attributes.url}
                title={item.Card_title}
                cardColor={item.Card_color}
                pageColor={pageColor}
              />
            </StyledSlide>
          ))}
        </Slider>
      </SliderWrap>
      <CardsList>
        {blockData.Card.map((item, idx) => (
          <li key={idx}>
            <Card
              desc={item.Card_text}
              icon={item.Card_icon.data.attributes.url}
              title={item.Card_title}
              cardColor={item.Card_color}
              pageColor={pageColor}
            />
          </li>
        ))}
      </CardsList>
    </>
  );
};

const SliderWrap = styled("div", {
  "@md": {
    display: "none",
  },
});

const StyledSlide = styled(SwiperSlide, {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px 16px",
  margin: "0 auto",
  "@md": {
    flexBasis: "calc(100%/3)",
    padding: 0,
  },
});

const CardsList = styled("ul", {
  display: "none",
  listStyle: "none",
  padding: 0,
  maxWidth: "1280px",
  margin: "0 auto",
  "@md": {
    display: "flex",
    justifyContent: "space-between",
    "&>li": {
      flexBasis: "calc(100%/3)",
      "&:not(:first-child)": {
        marginLeft: "30px",
      },
    },
  },
});
