import { useCallback, useEffect } from "react";

const KEY_EVENT_TYPE = "keyup";
const KEY_NAME_ARROWLEFT = "ArrowLeft";
const KEY_NAME_ARROWRIGHT = "ArrowRight";

export function useArrowKeys({
  next,
  prev,
}: {
  next: () => void;
  prev: () => void;
}) {
  const handleEscKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === KEY_NAME_ARROWLEFT) {
        prev();
      }
      if (event.key === KEY_NAME_ARROWRIGHT) {
        next();
      }
    },
    [next, prev]
  );

  useEffect(() => {
    document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);

    return () => {
      document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
    };
  }, [handleEscKey]);
}
