import React from "react";
import { styled } from "../../../../stitches.config";
import { ColoredCard } from "../../../types/pageBuilder";
import { getTextColor } from "../../../utils";

type Props = {
  cards: ColoredCard[];
};

export const ColoredCardsSection: React.FC<Props> = ({ cards }) => {
  return (
    <BlocksContainer>
      {!!cards?.length &&
        cards.map((block, i) => {
          const color = getTextColor(block.Content_color);
          return (
            <Block
              css={{ backgroundColor: block.Card_hex_color || "$accent" }}
              key={i}
            >
              {!!block.Card_icon && (
                <IconWrap>
                  <img src={block.Card_icon.data.attributes.url} />
                </IconWrap>
              )}
              <BlockTitle css={{ color }}>{block.Card_title}</BlockTitle>
              <BlockDesciption css={{ color }}>
                {block.Card_description}
              </BlockDesciption>
              {!!block.List_element && !!block.List_element.length && (
                <InnerList>
                  {block.List_element.map((item, i) => (
                    <InnerListEl css={{ color }} key={i}>
                      {item.text}
                    </InnerListEl>
                  ))}
                </InnerList>
              )}
            </Block>
          );
        })}
    </BlocksContainer>
  );
};

const BlocksContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "0 16px",
  margin: "40px auto 0",
  maxWidth: 640,
  "@md": {
    padding: 0,
    maxWidth: "1280px",
    flexDirection: "row",
    alignItems: "stretch",
  },
});

const BlockDesciption = styled("span", {
  fontSize: "14px",
  lineHeight: "21px",
  fontWeight: 500,
  marginBottom: 16,
});

const Block = styled("div", {
  padding: "24px",
  color: "$white",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  "&:not(:first-child)": {
    marginTop: "18px",
  },
  "@md": {
    "&:not(:first-child)": {
      margin: "0 0 0 32px",
    },
    "&>svg": {
      height: "50px",
      width: "50px",
    },
    width: "100%",
  },
});

const BlockTitle = styled("h3", {
  fontSize: "15px",
  lineHeight: "20px",
  fontWeight: 700,
  margin: "16px 0px",
  "@md": {
    fontSize: "16px",
    lineHeight: "24px",
  },
});

const InnerList = styled("ul", {
  display: "flex",
  flexWrap: "wrap",
  margin: 0,
  padding: 0,
  height: "100%",
  width: "100%",

  paddingLeft: 16,
  "@md": {
    flexWrap: "nowrap",
    flexDirection: "column",
  },
});

const InnerListEl = styled("li", {
  flexBasis: "calc(50% - 10px)",
  fontSize: "14px",
  lineHeight: "21px",
  fontWeight: 400,
  "&:nth-child(2n +1)": {
    marginRight: "12px",
  },
});

const IconWrap = styled("div", {
  width: "48px",
  height: "48px",
});
