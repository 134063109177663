import React, { useMemo } from "react";
import { SwiperSlide } from "swiper/react";
import { keyframes, styled } from "../../../../stitches.config";
import {
  ColoredTab,
  SectionBackgroundColorType,
} from "../../../types/pageBuilder";
import { chooseSliderColorScheme } from "../../../utils";
import { Slider } from "../../shared/Slider";
import { Tabs } from "../../shared/Tabs";

type Props = {
  pageColor?: string;
  backgroundColor: SectionBackgroundColorType;
  tabs: ColoredTab[];
  title?: string;
};

export const TabsSection: React.FC<Props> = ({
  pageColor = "$accent",
  backgroundColor,
  title,
  tabs,
}) => {
  const content = useMemo(
    () =>
      tabs.map((item) => ({
        title: item.Tab_title,
        content: (
          <TabContent>
            <CardTextContent>
              <CardText>{item.Tab_description}</CardText>
              {!!item.List_element && !!item.List_element.length && (
                <InnerList>
                  {item.List_element.map((item, i) => (
                    <InnerListEl key={i}>{item.text}</InnerListEl>
                  ))}
                </InnerList>
              )}
            </CardTextContent>
            <CardImgWrap>
              <img src={item.Tab_image.data.attributes.url} />
            </CardImgWrap>
          </TabContent>
        ),
      })),
    [tabs.length]
  );

  return (
    <>
      <StepsWrap css={{ "@md": { background: `${pageColor} !important` } }}>
        <TabsWrapper>
          {!!title && <Heading>{title}</Heading>}
          <Tabs
            tabsPosition="left"
            tabsBackground={"transparent"}
            tabs={content}
          ></Tabs>
        </TabsWrapper>
        <SliderWrapper>
          <Slider
            total={tabs.length}
            colorScheme={chooseSliderColorScheme(backgroundColor)}
          >
            {tabs.map((item, i) => (
              <StyledSlide key={i}>
                <Card>
                  <CardImgWrap>
                    <img src={item.Tab_image.data.attributes.url} />
                  </CardImgWrap>
                  <div>
                    <CardTextContent>
                      <CardTitle>{item.Tab_title}</CardTitle>
                      <CardText>{item.Tab_description}</CardText>
                      {!!item.List_element && !!item.List_element.length && (
                        <InnerList>
                          {item.List_element.map((item, i) => (
                            <InnerListEl key={i}>{item.text}</InnerListEl>
                          ))}
                        </InnerList>
                      )}
                    </CardTextContent>
                  </div>
                </Card>
              </StyledSlide>
            ))}
          </Slider>
        </SliderWrapper>
      </StepsWrap>
    </>
  );
};

const TabsWrapper = styled("div", {
  display: "none",
  height: "fit-content",
  "@md": {
    display: "block",
  },
});

const SliderWrapper = styled("div", {
  "@md": {
    display: "none",
  },
});

const StyledSlide = styled(SwiperSlide, {
  display: "flex",
  justifyContent: "center",
  minHeight: "100% !important",
  padding: "0px 18px",
  margin: "0 auto",
  height: "100% !important",
  "@sm": { padding: "0px 100px" },
  "@md": {
    flexBasis: "calc(100%/3)",
    padding: 0,
  },
});

const Card = styled("div", {
  padding: "24px",
  backgroundColor: "$accent",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  borderRadius: "8px",
  overflow: "scroll",
  "@ss": {
    maxWidth: "430px",
  },
  textAlign: "start",
  variants: {
    notActive: {
      true: {
        borderRadius: "0px",
        backgroundColor: "$secondaryBackground",
      },
    },
  },
});

const CardTitle = styled("span", {
  color: "$white",
  fontSize: "20px",
  lineHeight: "24px",
  fontWeight: 700,
  display: "block",
  margin: "18px 0px",
});

const CardText = styled("span", {
  width: "100%",
  fontWeight: 400,
  fontSize: "15px",
  lineHeight: "20px",
  color: "$white",
  display: "inline-block",
  maxHeight: "130px !important",
  overflow: "scroll",
  "@md": {
    overflow: "initial",
    maxHeight: "unset",
    width: "unset",
    fontSize: "16px",
    lineHeight: "24px",
    maxWidth: "520px",
  },
});

const CardImgWrap = styled("div", {
  display: "flex",
  justifyContent: "center",
  marginTop: "24px",

  "&>img": {
    height: "160px",
    width: "160px",

    margin: "0 auto",
  },
  "@md": {
    margin: "0 0 auto 0",
    alignContent: "flex-start",
    "&>img": {
      height: "256px",
      width: "256px",
    },
  },
});

const StepsWrap = styled("div", {
  margin: "0 auto",
  maxWidth: "1280px",
  "@md": {
    borderRadius: "8px",
    padding: "24px 32px",
    textAlign: "start",
  },
});
const TabsAnimation = keyframes({
  "0%": {
    opacity: 0,
  },
  "100%": {
    opacity: 1,
  },
});

const TabContent = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  variants: {
    animated: {
      true: {
        animation: `${TabsAnimation} 3000ms`,
      },
    },
  },
});

const Heading = styled("h2", {
  display: "none",

  "@md": {
    display: "inline-block",
    fontWeight: 700,
    color: "$white",
    fontSize: "24px",
    margin: "0px 0px 24px 0px",
    lineHeight: "28px",
  },
});
const InnerList = styled("ul", {
  display: "flex",
  color: "$white",
  flexWrap: "wrap",
  margin: 0,
  padding: 0,
  paddingLeft: 16,
  width: "100%",
  marginTop: "10px",
  "@md": {
    marginTop: "12px",
    flexWrap: "nowrap",
    flexDirection: "column",
  },
});

const InnerListEl = styled("li", {
  flexBasis: "calc((100% - 12px) / 2)",
  "&:nth-child(2n +1)": {
    marginRight: "12px",
  },
  fontSize: "13px",
  lineHeight: "18px",
  "@md": {
    flexBasis: "auto",
    fontSize: "15px",
    lineHeight: "21px",
    fontWeight: 400,
  },
});

const CardTextContent = styled("div", {});
