import React from "react";
import { styled } from "../../../stitches.config";
import SupportImg from "../../../static/icons/background/support-opacity.svg";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { routes } from "../../routes/routes";
import { SupportComponentProps } from "../../types/pageBuilder";

import Phone from "../../../static/icons/common/phone.svg";
import Email from "../../../static/icons/common/email.svg";
import { SupportContactLink } from "../shared/SupportContactLink";
import { useAppSelector } from "../../hooks/ReduxHooks";

export const isBrowser = () => typeof window !== "undefined";

export const SupportBlock: React.FC<SupportComponentProps> = ({
  pageColor = "$accent",
  blockType,
}) => {
  const { t } = useTranslation();
  const { email, primaryNumber } = useAppSelector((state) => state.contactInfo);
  return (
    <LiveSupportSection>
      <SupportBox css={{ backgroundColor: pageColor }} type={blockType}>
        <TextButtonsWrap>
          <TextWrap blockType={blockType}>
            <Heading>{t("supportHeading")}</Heading>
          </TextWrap>
          <ButtonsWrap type={blockType}>
            <SupportContactLink href={`tel:${primaryNumber}`}>
              <Phone /> <span>{primaryNumber}</span>
            </SupportContactLink>
            <SupportContactLink
              href={routes.contact.contactForm}
              routerLink={true}
            >
              <Email /> <span>{email}</span>
            </SupportContactLink>
          </ButtonsWrap>
        </TextButtonsWrap>
        {blockType === "standard" && (
          <ImageWrap>
            <SupportImg />
          </ImageWrap>
        )}
      </SupportBox>
    </LiveSupportSection>
  );
};

const LiveSupportSection = styled("section", {
  maxWidth: "1280px",
  margin: "0 auto",
  padding: "40px 0 80px",
});

const SupportBox = styled("div", {
  width: "100%",
  backgroundColor: "$accent",
  padding: "60px 24px",
  display: "flex",

  "@md": {
    padding: "48px 80px",
    borderRadius: "8px",
  },
  variants: {
    type: {
      standard: {
        justifyContent: "space-between",
      },
      minimized: {
        justifyContent: "center",
      },
    },
  },
});

const TextButtonsWrap = styled("div", {
  width: "100%",
  "@xs": {
    width: "unset",
    margin: "0 auto",
  },
  "@md": {
    margin: "auto 0",
  },
});

const Heading = styled("h2", {
  color: "$white",
  textTransform: "uppercase",
  fontSize: "24px",
  lineHeight: "28px",
  fontWeight: 700,
  margin: "0 0 12px",
  "@md": {
    fontSize: "32px",
    lineHeight: "40px",
  },
});

const TextWrap = styled("div", {
  textAlign: "center",
  variants: {
    blockType: {
      minimized: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        maxWidth: "100%",
        margin: 0,
      },
      standard: {
        "@md": {
          textAlign: "start",
          marginRight: "80px",
          maxWidth: "350px",
        },
      },
    },
  },
});

const ButtonsWrap = styled("div", {
  display: "flex",
  width: "100%",
  height: "112px",
  marginTop: "32px",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 16,
  "@md": {
    height: "unset",
  },
  variants: {
    type: {
      standard: { flexDirection: "column" },
      minimized: {
        marginTop: 16,
        "@md": {
          flexDirection: "row",
        },
      },
    },
  },
});

const ImageWrap = styled("div", {
  display: "none",
  "@md": {
    display: "block",
    marginRight: "80px",
  },
});
