import React, { useState } from "react";
import { SwiperSlide } from "swiper/react";
import { styled } from "../../../stitches.config";
import { ImageType, SectionBackgroundColorType } from "../../types/pageBuilder";
import { chooseSliderColorScheme } from "../../utils";
import { FullScreenImageGallery } from "../shared/FullScreenImageGallery";
import { Slider } from "../shared/Slider";

type Props = {
  backgroundColor: SectionBackgroundColorType;
  images: { attributes: { url: string } }[];
};

export const ImageGalleryBlock: React.FC<Props> = ({
  images,
  backgroundColor,
}) => {
  const [externalSwitchCounter, setExternalSwitchCounter] = useState(-1);
  const [galleryOpened, setGalleryOpened] = useState(false);
  const currentImage = images[externalSwitchCounter]?.attributes.url;

  const sliderColor = chooseSliderColorScheme(backgroundColor);

  return (
    <>
      <Slider
        wide
        total={images.length}
        externalSwitchCounter={externalSwitchCounter}
        colorScheme={sliderColor}
        disabledLayers={true}
      >
        {images.map((item, i) => (
          <StyledSlide
            onClick={() => {
              setExternalSwitchCounter(i);
              setGalleryOpened(true);
            }}
            key={i}
          >
            {({ isActive }) => {
              return (
                <Card
                  css={{ backgroundColor: isActive ? "$white" : sliderColor }}
                  notActive={!isActive}
                >
                  <StatisticImage src={item.attributes.url} />
                </Card>
              );
            }}
          </StyledSlide>
        ))}
      </Slider>
      {!!currentImage && galleryOpened && (
        <FullScreenImageGallery
          close={() => setGalleryOpened(false)}
          current={externalSwitchCounter}
          prev={() => setExternalSwitchCounter((prevState) => prevState - 1)}
          next={() => setExternalSwitchCounter((prevState) => prevState + 1)}
          totalElements={images.length}
          image={currentImage}
        />
      )}
    </>
  );
};

const StyledSlide = styled(SwiperSlide, {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px 25px",
  margin: "0",
  cursor: "pointer",
  height: "100% !important",
  overflow: "hidden",
  "@md": {
    padding: 0,
  },
});

const StatisticImage = styled("img", {
  objectFit: "contain",
  height: "400px ",
  width: "100%",
});

const Card = styled("div", {
  padding: "24px",
  backgroundColor: "$white",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  objectFit: "cover",
  transition: "all 500ms ease-in-out",
  variants: {
    notActive: {
      true: {
        borderRadius: "0px",
        backgroundColor: "$secondaryBackground",
      },
    },
  },
});
