import React from "react";
import { styled } from "../../../stitches.config";
import { CategorizedListComponentProps } from "../../types/pageBuilder";

export const CategorizedListBlock: React.FC<CategorizedListComponentProps> = ({
  blocks,
}) => {
  return (
    <>
      {!!blocks.length &&
        blocks.map((item, i) => (
          <ListWrap key={i}>
            <ListTitle>{item.Category_title}</ListTitle>
            <RealizedList last={blocks.length - 1 === i}>
              {item.List_element.map((el, i) => (
                <CompanyListEl key={i}>
                  {!!el.Card_icon?.data?.attributes.url && (
                    <ImageContainer>
                      <img src={el.Card_icon.data.attributes.url} />
                    </ImageContainer>
                  )}
                  <CompanyTitle>{el.Card_title}</CompanyTitle>
                </CompanyListEl>
              ))}
            </RealizedList>
          </ListWrap>
        ))}
    </>
  );
};

const ListWrap = styled("div", {
  maxWidth: "1280px",
  "@md": {
    margin: "40px auto 0",
  },
});
const ListTitle = styled("span", {
  margin: "0px 0px 24px 16px",
  fontWeight: 600,
  fontSize: "17px",
  lineHeight: "24px",
  color: "$heading",
  "@md": {
    margin: "0px 0px 24px 0px",
  },
});

const RealizedList = styled("ul", {
  display: "flex",
  listStyle: "none",
  padding: 0,
  overflowX: "auto",
  overflowY: "hidden",
  alignItems: "center",
  margin: "28px 0px 30px 0px",
  "@md": {
    flexWrap: "wrap",
    margin: "0px",
  },
  variants: {
    last: {
      true: {
        marginBottom: 0,
      },
    },
  },
});

const ImageContainer = styled("div", {
  "&>img": {
    width: "100%",
    objectFit: "contain",
    objectPosition: "left center",
    height: "54px",
  },
});
const CompanyTitle = styled("span", {
  lineHeight: "20px",
  fontSize: "15px",
  color: "$text",
  fontWeight: 700,
  "@md": {
    lineHeight: "24px",
    fontSize: "16px",
  },
});

const CompanyListEl = styled("li", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 25,
  backgroundColor: "$secondaryBackground",
  borderRadius: "8px",
  minWidth: "302px",
  maxWidth: "302px",
  padding: "24px",
  height: "100%",
  marginRight: "16px",
  "&:first-child": {
    marginLeft: "16px",
  },
  "@md": {
    marginRight: "0px",
    "&:first-child": {
      marginLeft: "0px",
    },
    "&:nth-child(4n)": {
      marginRight: "0px !important",
    },
    marginTop: "24px",
    minWidth: "unset",
    maxWidth: "unset",
    flexBasis: "calc((100% - 72px) / 4)",
    "&:not(:last-child)": {
      marginRight: "24px",
    },
  },
});
