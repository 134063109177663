import React, { useMemo } from "react";
import {
  CardListBlockType,
  ColoredCardListBlockType,
  DynamicBlocks,
  HeroBlockType,
  PageBuilderBlocks,
  CategorizedCardsBlockType,
  SupportBlockType,
  RichtextBlockType,
  ColoredTabsBlockType,
  ImageGalleryBlockType,
} from "../../types/pageBuilder";
import { CardsBlock } from "./CardsBlock/CardsBlock";
import { ColoredCardsSection } from "./ColoredCardsBlock/ColoredCardsSection";
import { HeroBlock } from "./HeroBlock/HeroBlock";
import { CategorizedListBlock } from "./CategorizedListBlock";
import { SectionHoc } from "./SectionHoc";
import { SupportBlock } from "./SupportBlock";
import { TabsSection } from "./ColoredCardsBlock/TabsSection";
import { ImageGalleryBlock } from "./ImageGalleryBlock";
import { getSectionVisibility } from "../../utils";

type Props = {
  block: DynamicBlocks;
  pageThemeColor?: string;
  pageSlug: string;
};

export const BlockDefiner: React.FC<Props> = ({
  block,
  pageThemeColor,
  pageSlug,
}) => {
  const blockVisibility = useMemo(() => {
    return getSectionVisibility(block);
  }, [block.__typename, pageSlug]);

  const pageColor = pageThemeColor?.match(/^#([0-9a-f]{3}){1,2}$/i)
    ? pageThemeColor
    : "$accent";

  if (blockVisibility) {
    return null;
  }

  switch (block.__typename) {
    case PageBuilderBlocks.hero: {
      const heroBlock = block as HeroBlockType;
      return (
        <HeroBlock
          backgroundColor={heroBlock.Hero_background}
          heroImage={heroBlock.Hero_image}
          pageColor={pageColor}
          description={heroBlock.Page_hero_description}
          headline={heroBlock.Page_headline}
          imagePosition={heroBlock.Hero_image_position}
          mobileTextColor={heroBlock.Hero_mobile_text_color}
          button={heroBlock.Hero_button}
        />
      );
    }

    case PageBuilderBlocks.supportSection: {
      const supportBlock = block as SupportBlockType;
      return (
        <SupportBlock
          pageColor={pageColor}
          blockType={supportBlock.Support_block_type}
        />
      );
    }

    case PageBuilderBlocks.cardListSection: {
      const cardListBlock = block as CardListBlockType;
      return (
        <SectionHoc
          spacing={cardListBlock.Card_list_section_spacing}
          title={cardListBlock.Card_list_section_title}
          backgroundColor={cardListBlock.Card_list_section_background_color}
        >
          <CardsBlock
            backgroundColor={cardListBlock.Card_list_section_background_color}
            pageColor={pageColor}
            blockData={cardListBlock}
          />
        </SectionHoc>
      );
    }

    case PageBuilderBlocks.coloredCardsSection: {
      const coloredCardListBlock = block as ColoredCardListBlockType;
      return (
        <SectionHoc
          spacing={coloredCardListBlock.Colored_cards_section_spacing}
          title={coloredCardListBlock.Colored_cards_section_title}
          backgroundColor={
            coloredCardListBlock.Colored_cards_section_background_color
          }
        >
          <ColoredCardsSection cards={coloredCardListBlock.Colored_card} />
        </SectionHoc>
      );
    }

    case PageBuilderBlocks.categorizedListSection: {
      const categorizedListsBlock = block as CategorizedCardsBlockType;
      return (
        <SectionHoc
          spacing={categorizedListsBlock.Categorized_list_section_spacing}
          title={categorizedListsBlock.Categorized_list_section_title}
          backgroundColor={
            categorizedListsBlock.Categorized_list_section_background_color
          }
        >
          <CategorizedListBlock blocks={categorizedListsBlock.Category_list} />
        </SectionHoc>
      );
    }
    case PageBuilderBlocks.richtextSection: {
      const richTextBlock = block as RichtextBlockType;
      return (
        <SectionHoc
          title={richTextBlock.Richtext_content_section_title}
          spacing={richTextBlock.Richtext_content_section_spacing}
          backgroundColor={
            richTextBlock.Richtext_content_section_background_color
          }
          content={richTextBlock.Richtext_content_section_content}
        />
      );
    }

    case PageBuilderBlocks.coloredTabsSection: {
      const coloredTabsBlock = block as ColoredTabsBlockType;
      return (
        <SectionHoc
          title={coloredTabsBlock.Colored_tabs_section_title}
          spacing={coloredTabsBlock.Colored_tabs_section_spacing}
          backgroundColor={
            coloredTabsBlock.Colored_tabs_section_background_color
          }
          css={{ "@md": { "&>h2": { display: "none" } } }}
        >
          <TabsSection
            title={coloredTabsBlock.Colored_tabs_section_title}
            backgroundColor={
              coloredTabsBlock.Colored_tabs_section_background_color
            }
            tabs={coloredTabsBlock.Tab}
            pageColor={pageColor}
          />
        </SectionHoc>
      );
    }
    case PageBuilderBlocks.gallerySection: {
      const galleryBlock = block as ImageGalleryBlockType;
      return (
        <SectionHoc
          title={galleryBlock.Gallery_section_title}
          spacing={galleryBlock.Gallery_section_spacing}
          backgroundColor={galleryBlock.Gallery_section_background_color}
        >
          <ImageGalleryBlock
            backgroundColor={galleryBlock.Gallery_section_background_color}
            images={galleryBlock.Gallery_images.data}
          />
        </SectionHoc>
      );
    }
    default:
      return null;
  }
};
