import React from "react";
import { styled } from "../../../../stitches.config";
import { CustomStylesType } from "../../../types/pageBuilder";
import { ListCheckbox } from "../../shared/ListCheckbox";

type Props = {
  content: string;
  customStyles?: CustomStylesType;
  customColor?: string;
};

const SelectListStyle: React.FC<{
  style: string;
  items: string[];
  customStyles?: CustomStylesType;
}> = ({ style, items, customStyles }) => {
  const { Li } = customStyles || {};

  const ListItem = Li || Item;
  switch (style) {
    case "ordered":
      return (
        <>
          {items.map((el, i) => (
            <ListBox key={i}>
              <ListItem
                dangerouslySetInnerHTML={{ __html: `${i + 1}. ${el}` }}
              ></ListItem>
            </ListBox>
          ))}
        </>
      );
    case "unordered":
      return (
        <UnorderedList>
          {items.map((item, i) => (
            <ListItem key={i}>
              <span dangerouslySetInnerHTML={{ __html: `${item}` }}></span>
            </ListItem>
          ))}
        </UnorderedList>
      );

    default:
      return null;
  }
};

const FillTable: React.FC<{ table: any }> = ({ table }) => {
  const content = table.withHeadings ? table.content.slice(1) : table.content;
  return (
    <TableContainer>
      <Table>
        {table.withHeadings && (
          <thead>
            <tr>
              {table.content[0].map((element: any, index: number) => (
                <th key={index}>
                  <TableText
                    css={{ fontWeight: 700 }}
                    dangerouslySetInnerHTML={{ __html: `${element}` }}
                  ></TableText>
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {content.map((el: any, i: number) => {
            return (
              <tr key={i}>
                {el.map((element: any, index: number) => (
                  <Td key={index}>
                    <TableText
                      dangerouslySetInnerHTML={{ __html: `${element}` }}
                    ></TableText>
                  </Td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </TableContainer>
  );
};

const WarningRenderer: React.FC<{ warning: any }> = ({ warning }) => {
  return (
    <WarningBox>
      <WarningTitle>{warning.title}</WarningTitle>
      <WarningText>{warning.message}</WarningText>
    </WarningBox>
  );
};

const ImagesRender: React.FC<{ images: any }> = ({ images }) => {
  return (
    <ImageContainer>
      <ArticleImage
        css={{ margin: "0 auto !important" }}
        src={images.file.url}
        alt="article image"
      />
    </ImageContainer>
  );
};

const SelectHeaderLevel: React.FC<{ size: number; text: string }> = ({
  size,
  text,
}) => {
  switch (size) {
    case 1:
      return <Title dangerouslySetInnerHTML={{ __html: text }} />;
    case 2:
      return <Subtitle dangerouslySetInnerHTML={{ __html: text }} />;
    case 3:
      return <H3 dangerouslySetInnerHTML={{ __html: text }} />;
    case 4:
      return <H4 dangerouslySetInnerHTML={{ __html: text }} />;
    case 5:
      return <H5 dangerouslySetInnerHTML={{ __html: text }} />;
    case 6:
      return <H6 dangerouslySetInnerHTML={{ __html: text }} />;
    default:
      return null;
  }
};

const CheckListRenderer: React.FC<{ checklistItems: any }> = ({
  checklistItems,
}) => {
  return (
    <CheckBoxList>
      {checklistItems.items.map((el: any, i: number) => (
        <ListCheckbox key={i} label={el.text} checked={el.checked} />
      ))}
    </CheckBoxList>
  );
};

const Builder: React.FC<{
  element: any;
  customStyles?: CustomStylesType;
  customColor: string;
}> = ({ element, customStyles, customColor }) => {
  const { P } = customStyles || {};

  switch (element.type) {
    case "paragraph": {
      const Element = P || Text;
      return (
        <Element dangerouslySetInnerHTML={{ __html: `${element.data.text}` }} />
      );
    }
    case "header":
      return (
        <SelectHeaderLevel size={element.data.level} text={element.data.text} />
      );
    case "list":
      return (
        <SelectListStyle
          customStyles={customStyles}
          style={element.data.style}
          items={element.data.items}
        />
      );
    case "image":
      return <ImagesRender images={element.data} />;
    case "delimiter":
      return <Delimiter>* * *</Delimiter>;
    case "checklist":
      return <CheckListRenderer checklistItems={element.data} />;
    case "warning":
      return <WarningRenderer warning={element.data} />;
    case "table":
      return <FillTable table={element.data} />;
    case "quote":
      return (
        <Quote>
          <QuoteText>"{element.data.text}"</QuoteText>
          <QuoteAuthor>
            - {element.data.caption || "Unknown author"}.
          </QuoteAuthor>
        </Quote>
      );
    case "LinkTool":
      return (
        <Link
          css={{ color: customColor }}
          href={element.data.link}
          target="_blank"
        >
          {element.data.meta.title || element.data.link}
        </Link>
      );
    default:
      return null;
  }
};

export const DynamicPagesRichTextParser: React.FC<Props> = ({
  content,
  customStyles,
  customColor = "$accent",
}) => {
  const richTextObj = eval("({obj:[" + content + "]})");

  const { Container, BuilderWrapper } = customStyles || {};
  const Wrapper = Container || Wrap;
  const BuilderContainer = BuilderWrapper || BuilderWrap;

  return (
    <Wrapper>
      {richTextObj.obj[0].blocks.map((el: any, i: number) => (
        <BuilderContainer key={i}>
          <Builder
            customColor={customColor}
            customStyles={customStyles}
            element={el}
          />
        </BuilderContainer>
      ))}
    </Wrapper>
  );
};

const TableContainer = styled("div", {
  width: "100%",
  overflow: "auto",
  padding: 5,
});

const Table = styled("table", {
  borderCollapse: "collapse",
  margin: "18px auto",
  borderRadius: "8px",
  borderStyle: "hidden",
  overflow: "hidden",
  boxShadow: "0 0 0 1px #9F9F9F",
  th: {
    border: "1px solid #9F9F9F",
    backgroundColor: "$primaryBackground",
    padding: "8px",
    textAlign: "left",
  },
});

const Td = styled("td", {
  verticalAlign: "top",
  border: "1px solid #9F9F9F",
  padding: "8px",
  maxWidth: 200,
});

const TableText = styled("p", {
  fontWeight: "400",
  fontSize: "18px",
  margin: 0,
  lineHeight: 1.5,
});

const Wrap = styled("div", {
  maxWidth: 1280,
  margin: "0 auto",
  padding: "0 16px",
  "@md": {
    padding: 0,
  },
  a: {
    textDecoration: "underline",
    color: "inherit",
    fontWeight: 600,
    "@md": { textDecoration: "none" },
  },
});

const WarningBox = styled("div", {
  margin: "18px auto",
  border: "1px solid $accent",
  borderRadius: "10px",

  padding: "12px",
  backgroundColor: "$secondaryBackground",
  width: "fit-content",
  "@md": {
    padding: "12px",
  },
});

const WarningTitle = styled("h3", {
  color: "$warning",
  margin: "0 0 8px",
  fontWeight: 700,
  paddingBottom: 4,
  borderBottom: "1px solid $accent",
});

const WarningText = styled("p", {
  margin: 0,
  color: "$warning",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
});

const CheckBoxList = styled("div", {
  margin: "30px 0",
});

const Quote = styled("div", {
  borderLeft: "5px solid $accent",
  padding: "8px",
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  backgroundColor: "$secondaryBackground",
  width: "fit-content",
  margin: "0 auto",
  minWidth: 240,
});

const QuoteText = styled("p", {
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  margin: "0 0 10px",
});

const QuoteAuthor = styled("p", {
  fontWeight: "600",
  fontSize: "18px",
  lineHeight: "24px",

  margin: "0px",
  textAlign: "end",
});

const Title = styled("h1", {
  fontWeight: "600",
  fontSize: "30px",
  lineHeight: "32px",
  margin: "0 24px",
  "@md": {
    fontSize: "40px",
    lineHeight: "50px",
  },
});

const Subtitle = styled("h2", {
  fontWeight: "600",
  fontSize: "26px",
  lineHeight: "30px",
  margin: "0 0 24px",
  "@md": {
    fontSize: "34px",
    lineHeight: "40px",
  },
});

const H3 = styled("h3", {
  fontWeight: "600",
  fontSize: "22px",
  lineHeight: "28px",
  margin: "0 0 24px",
  "@md": {
    fontSize: "30px",
    lineHeight: "36px",
  },
});

const H4 = styled("h4", {
  fontWeight: "600",
  fontSize: "20px",
  lineHeight: "26px",
  margin: "0 0 24px",
  "@md": {
    fontSize: "26px",
    lineHeight: "32px",
  },
});

const H5 = styled("h4", {
  fontWeight: "600",
  fontSize: "18px",
  lineHeight: "22px",
  margin: "0 0 24px",
  "@md": {
    fontSize: "26px",
    lineHeight: "32px",
  },
});

const H6 = styled("h4", {
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "21px",
  margin: "0 0 24px",
  "@md": {
    fontSize: "26px",
    lineHeight: "32px",
  },
});

const Text = styled("p", {
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "30px",
  margin: "5px 0px",
});

const Delimiter = styled("p", {
  fontWeight: "500",
  fontSize: "30px",
  lineHeight: "30px",
  margin: "30px auto",
  textAlign: "center",
});

const Link = styled("a", {
  display: "block",
  fontWeight: "600",
  fontSize: "18px",
  lineHeight: "30px",
  margin: "0 0 20px",
  color: "$accent",
  textDecoration: "none",
  cursor: "pointer",
  "&:hover": {
    opacity: 0.9,
  },
});

const ArticleImage = styled("img", {
  objectFit: "contain",
  borderRadius: "8px",
  display: "block",
  maxWidth: "100%",
  maxHeight: "400px",
  "@md": {
    maxHeight: "500px",
  },
});

const ImageContainer = styled("div", {
  height: "100%",
  margin: "12px 0px",
  "@md": {
    height: "500px",
  },
});

const UnorderedList = styled("ul", {
  display: "flex",
  alignItems: "start",
  flexDirection: "column",
  padding: "0 16px",
  textAlign: "start",
  gap: 10,
  margin: "0 0 10px",
  "@md": {
    gap: 20,
    margin: "0 0 20px",
  },
});

const Item = styled("li", {
  ontWeight: "400",
  fontSize: "18px",
  lineHeight: "30px",
  margin: 0,
});

const ListBox = styled("ul", {
  display: "flex",
  alignItems: "center",
  padding: 0,
  listStyle: "none",
  textAlign: "start",
  gap: 10,
  margin: "0 0 10px",
  "@md": {
    gap: 20,
    margin: "0 0 20px",
  },
});

const BuilderWrap = styled("div", {
  margin: "16px 0",
  textAlign: "start",
  "@md": {
    margin: "32px 0",
    textAlign: "center",
  },
});
