import React from "react";
import { styled } from "../../../stitches.config";
import { SectionHocProps } from "../../types/pageBuilder";
import { DynamicPagesRichTextParser } from "./shared/RichTextParser";

export const SectionHoc: React.FC<SectionHocProps> = ({
  title,
  content,
  children,
  backgroundColor,
  spacing,
  css,
}) => {
  return (
    <SectionWrap spacing={spacing} backgroundColor={backgroundColor} css={css}>
      {!!title && <Title>{title}</Title>}
      {!!content && <DynamicPagesRichTextParser content={content} />}
      {children}
    </SectionWrap>
  );
};

const SectionWrap = styled("section", {
  variants: {
    spacing: {
      thin: {
        padding: "16px 0px",
        "@md": {
          padding: "32px 0px",
        },
      },
      regular: {
        padding: "32px 0px",
        "@md": {
          padding: "48px 0px",
        },
      },
      wide: {
        padding: "48px 0px",
        "@md": {
          padding: "64px 0px",
        },
      },
    },
    backgroundColor: {
      light_green: {
        backgroundColor: "$primaryBackground",
      },
      light_gray: {
        backgroundColor: "$secondaryBackground",
      },
      white: {
        backgroundColor: "$white",
      },
    },
  },
});

const Title = styled("h2", {
  textAlign: "center",
  margin: "0px 16px 40px 16px",
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "24px",
  color: "$heading",
  "@md": {
    fontSize: "24px",
    lineHeight: "36px",
    margin: "0px 0px 48px 0px",
  },
});
