import { Article } from "../types";

type QueueArgs = {
    data: Article[];
    slug: string;
    setQueue: (queue: [string, string]) => void;
  };
  
  
  export const findQueue = ({ data, slug, setQueue }: QueueArgs) => {
    const currentElementIdx = data.findIndex(
      (item: Article) => item.attributes.slug === slug
    );
    // if element found need to get a previous and the next one in collection for navigation
    if (currentElementIdx >= 0) {
      const prev = data[currentElementIdx - 1]
        ? data[currentElementIdx - 1].attributes.slug
        : "";
      const next = data[currentElementIdx + 1]
        ? data[currentElementIdx + 1].attributes.slug
        : "";
      setQueue([prev, next]);
    } else {
      setQueue(["", ""]);
    }
  };